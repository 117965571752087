import React, { useEffect } from 'react'
import logoImage from '../../Assets/LogoHome.png'
import HeroHome from '../../Components/Utils/HeroHome'
import somosImg from '../../Assets/SomosImg.png'
import serviciosImg from '../../Assets/ServiciosImg.png'
import { Fade } from 'react-reveal'
import botonHablemos from '../../Assets/botonhablemos.png'
import { NavLink } from 'react-router-dom'

function HomeScreen() {



    return (
        <div className='snap-y snap-mandatory relative w-full h-screen overflow-y-auto overflow-x-hidden scroll-smooth'>

            <div className='snap-start h-screen'>
                <HeroHome opacity={'opacity-[0]'} bgImage={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Simplesoluciones/pexels-pressmaster-3129957-3840x2160-25fps.mp4'} image={logoImage} primerParrafo={'Simple Soluciones'} segundoParrafo={'Consultora de transformación digital y marketing'} show={'hidden'} />
            </div>
            <div className='snap-start'>
                <div className='w-full relative noise'>
                    <div className='max-w-[1000px] 2xl:max-w-[1200px] w-full flex flex-col justify-center items-center gap-5 2xl:h-screen pt-32 md:py-24 2xl:py-0 text-white relative z-20 mx-auto p-5 md:px-0'>
                        <div className='self-end md:w-full md:border-t-2 border-b-2'>
                            <Fade left>
                                <h2 className='2xl:text-7xl md:text-5xl text-4xl text-right md:text-left pb-3 2xl:p-14 md:p-10 md:w-full'>QUIÉNES SOMOS</h2>
                            </Fade>
                        </div>
                        <div className='flex flex-col-reverse md:flex-row w-full justify-between md:pl-14 items-center gap-10'>
                            <img src={somosImg} alt="" className='md:w-[30%] 2xl:w-[40%] w-[300px] self-end md:self-center' />
                            <p className='text-right md:text-center text-base self-start md:border-b-2 md:py-8'>
                                En nuestra compañía, nos destacamos por ser expertos en comprender profundamente el comportamiento de los clientes. A través de esta especialización, diseñamos y creamos flujos de trabajo altamente personalizados que se enfocan en optimizar los procesos internos. Nuestro objetivo principal es aumentar significativamente la satisfacción del cliente, mientras trabajamos arduamente para mejorar la productividad general de nuestro equipo. Además, estas estrategias están cuidadosamente diseñadas para maximizar los ingresos, asegurando un crecimiento sostenido y beneficios a largo plazo para nuestra empresa. Nos comprometemos a ofrecer soluciones efectivas que se alinean perfectamente con las necesidades de nuestros clientes, garantizando así resultados excepcionales
                            </p>
                        </div>
                    </div>
                    <div className='bg-[#409891] w-[1000px] md:h-[400px] 2xl:h-[500px] rounded-full blur-[200px] absolute bottom-0 right-0 z-'></div>

                </div>
            </div>
            <div className='snap-end'>
                <div className='w-full relative noise'>
                    <div className='max-w-[1000px] 2xl:max-w-[1200px] w-full flex flex-col justify-center  2xl:h-screen h-min 2xl:py-0 py-24 overflow-hidden text-white relative z-20 mx-auto p-5 md:px-0'>
                        <div className='w-full md:border-t-2 md:border-b-2 flex gap-5 md:gap-0 flex-col md:flex-row justify-between items-center py-10'>
                            <h2 className='2xl:text-7xl md:text-5xl text-4xl w-full border-t-2 border-b-2 md:border-t-0 md:border-b-0 p-5 md:p-0'>SERVICIOS</h2>
                            <p className='text-right md:text-left text-sm md:text-lg 2xl:w-[70%] md:w-full w-[80%] border-b-2 md:border-b-0 self-end pb-2 md:pb-0'>Nuestros servicios de consultoría se enfocan a entregar soluciones para PYMES y empresas de forma rápida e integral, buscando entregar en un corto plazo una solución real a sus desafíos, apoyándolos en el crecimiento.</p>
                        </div>
                        <div className='flex justify-between items-center bg-grilla md:bg-contain bg-cover bg-no-repeat md:bg-repeat  '>
                            <div className='md:border-r-2 md:self-start md:w-[29%] 2xl:w-[30%] w-full '>
                                <ul className='flex justify-center flex-col items-center'>
                                    <li className='flex items-center md:border-b-2 py-10 gap-10 md:pr-10  w-[50%] md:w-full'>
                                        <span className='font-thin text-5xl md:w-[30%]'>
                                            01
                                        </span>
                                        <h3 className='text-base font-normal'>Transformación digital</h3>
                                    </li>
                                    <li className='flex  items-center md:border-b-2 py-10 gap-10 md:pr-10 w-[50%] md:w-full'>
                                        <span className='font-thin text-5xl md:w-[30%]'>
                                            02
                                        </span>
                                        <h3 className='text-base font-normal'>Actividad Comercial Digital</h3>
                                    </li>
                                    <li className='flex  items-center md:border-b-2 py-10 gap-10 md:pr-10 w-[50%] md:w-full '>
                                        <span className='font-thin text-5xl md:w-[30%]'>
                                            03
                                        </span>
                                        <h3 className='text-base font-normal'>Estrategia de Marca y Comunicación</h3>
                                    </li>
                                    <li className='flex  items-center md:border-b-2 py-10 gap-10 md:pr-10 w-[50%] md:w-full'>
                                        <span className='font-thin text-5xl md:w-[30%]'>
                                            04
                                        </span>
                                        <h3 className='text-base font-normal'>Reporteria</h3>
                                    </li>
                                </ul>
                            </div>

                            <img src={serviciosImg} alt="" className='w-[500px] hidden md:block' />


                        </div>
                    </div>
                </div>
            </div>
            <div className='snap-start'>
                <div className='w-full relative noise'>
                    <div className='max-w-[1000px] 2xl:max-w-[1200px] w-full flex flex-col md:flex-row justify-center items-center gap-5 h-screen  2xl:py-0 md:py-24 overflow-hidden text-white relative z-20 mx-auto p-5 md:p-0'>
                        <div className='flex flex-col md:justify-center md:items-center gap-5'>
                            <div className='flex md:justify-center md:items-center gap-5'>
                                <h2 className='2xl:text-7xl md:text-5xl text-5xl text-left'>CONTACTO</h2>
                                <div className='border-b-2 border-white md:w-[200px]'></div>
                            </div>
                            <p className='self-end md:w-96 md:text-right'>
                                ¿Necesitas una solución o asesoramiento?
                                Ponte en contacto con nuestro equipo especialista.
                            </p>
                        </div>
                        <NavLink to='/contacto'>
                            <img src={botonHablemos} alt="" className='w-[200px] cursor-pointer self-end md:self-center' />
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeScreen