import React from 'react'
import Hero from '../../Components/Utils/Hero'
import NosotrosImg from '../../Assets/NosotrosImg.png'
import CardNosotros from '../../Components/Utils/CardNosotros'


function NosotrosScreen() {
    return (
        <div className='snap-y snap-mandatory relative w-full h-screen overflow-y-auto overflow-x-hidden scroll-smooth'>
            <div className='snap-start h-screen'>
                <Hero opacity={'opacity-[0.8]'} dotsNumber={2} activeDot={0} bgImage={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Simplesoluciones/mixkit-young-team-brainstorms-ideas-with-sticky-notes-8813-medium.mp4'} primerParrafo={'CONECTANDO EL FUTURO'} segundoParrafo={'TECNOLOGIA PARA TRANSFORMAR EL PRESENTE'} />
            </div>
            <div className='snap-start'>
                <div className='w-full relative noise'>
                    <div className='max-w-[1000px] 2xl:max-w-[1200px] w-full flex flex-col gap-5 justify-center items-center h-min py-36  text-white relative z-20 mx-auto p-5 md:px-0'>
                        <div className='w-full flex flex-col md:flex-row justify-center items-center'>
                            <div className='w-full flex flex-col'>
                                <div className=' border-t-2 py-8 w-[90%]'>
                                    <h2 className='2xl:text-7xl md:text-5xl text-4xl text-left md:pl-10'>NOSOTROS</h2>
                                </div>
                                <div className='border-t-2 w-[90%] py-8 self-end md:self-start'>
                                    <p className='text-left text-base md:pl-10'>Grupo Simple es una empresa con más de 9 años en Chile, respaldada por el grupo Scale Capital, con foco en entregar soluciones rápidas, flexibles y sencillas a nuestros clientes. Hoy Simple está presente en 4 países de Latinoamérica, entregando soluciones digitales a sus clientes.</p>
                                </div>
                                <div className='border-t-2 self-start w-[50%] md:w-full'></div>
                            </div>
                            <div>
                                <img src={NosotrosImg} className='w-[500px]' />
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row justify-start w-full gap-5'>
                            <CardNosotros titulo={'01 MISIÓN'} parrafo={'Nuestra misión es ser una empresa líder en entregar soluciones digitales en el menor tiempo posible, ofreciendo productos fáciles de entender, siendo ágiles y resolutivos en las respuestas. Para ello necesitamos que nuestros sistemas y procesos sean fáciles de operar, permitiendo a nuestros colaboradores entregar respuestas claras con la agilidad que nuestros clientes requieren.'} color={'black'} bgColor={'bg-white'} />
                            <CardNosotros titulo={'02 VISIÓN'} parrafo={'Nuestra visión en Simple Soluciones es convertirnos en la consultora de transformación digital y marketing más confiable y exitosa de Hispanoamérica. Queremos ser reconocidos por nuestra calidad de servicio, nuestra capacidad para adaptarnos a los cambios del mercado y nuestra habilidad para generar impacto positivo en los negocios de nuestros clientes. Buscamos ser pioneros en la implementación de nuevas tecnologías y tendencias, y ser la referencia en el ámbito del marketing digital en la industria Hispanoaméricana. Trabajamos con pasión y dedicación para ayudar a las empresas a maximizar su presencia en línea, alcanzar el crecimiento y el éxito sostenible en un mundo digital en constante evolución.'} color={'white'} bgColor={'bg-[#245854]'} />

                        </div>
                    </div>
                    <div className='bg-[#409891] w-[1000px] md:h-[400px] 2xl:h-[500px] rounded-full blur-[200px] absolute bottom-0 left-0 '></div>
                </div>
            </div>
        </div>
    )
}

export default NosotrosScreen