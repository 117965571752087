import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaRegArrowAltCircleRight } from "react-icons/fa";


function CardServicios({ titulo, list1, list2, list3, list4,descriptionDetail }) {
    return (
        <div className='bg-white justify-between items-center flex flex-col gap-10 p-10 text-black rounded-3xl'>
            <h3 className='text-2xl text-center'>{titulo}</h3>
            <div className='flex 2xl:flex-col flex-col gap-10 md:flex-row items-end w-full justify-between'>
                <ul className='self-start list-disc'>
                    <li className=''>
                        <p className='text-base text-left'>{list1}</p>
                    </li>
                    <li className=''>
                        <p className='text-base text-left'>{list2}</p>
                    </li>
                    <li className=''>
                        <p className='text-base text-left'>{list3}</p>
                    </li>
                    {
                        list4 &&
                        <li className=''>
                            <p className='text-base text-left'>{list4}</p>
                        </li>

                    }
                </ul>
                <NavLink to={{pathname:'/servicios-details'}} state={{
                    titulo: titulo,
                    descriptionDetail:descriptionDetail
                
                }} className={'2xl:self-end flex justify-center items-center gap-5 '}>
                    <p className='text-xl'>Ver más</p>
                    <FaRegArrowAltCircleRight size={30} />
                </NavLink>
            </div>

        </div>
    )
}

export default CardServicios