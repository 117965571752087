import React, { useState } from 'react'
import logo from '../../Assets/Logo.png'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

function ContactoScreen() {
    const [value, setValue] = useState({
        name_client: '',
        last_name_client: '',
        company_name: '',
        country: '',
        email: '',
        contact_number: '',
        message: ''
    })


    const handleSubmit = (e) => {
        e.preventDefault()
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    const validate = () => {
        if (value.nombres === '' || value.apellidos === '' || value.compañia === '' || value.pais === '' || value.correo === '' || value.telefono === '' || value.mensaje === '') {
            return false
        } else {
            return true
        }

    }

    const submit = async (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                const response = await axios.post('https://r57rbi7bwb.execute-api.sa-east-1.amazonaws.com/production/simplesolution', {
                    name_client: value.name_client,
                    last_name_client: value.last_name_client,
                    company_name: value.company_name,
                    country: value.country,
                    email: value.email,
                    contact_number: value.contact_number,
                    message: value.message
                })
                if (response.status === 201) {
                    confirmAlert({
                        title: 'Mensaje enviado',
                        message: 'Gracias por contactarnos, te responderemos a la brevedad',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => window.location.reload()
                            }
                        ]
                    });
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            console.log('no se puede enviar')
        }

    }



    return (
        <div className='bg-white text-black mt-10 pt-10'>
            <div className='flex md:max-w-[1100px] h-screen mx-auto md:text-center flex-col md:flex-row justify-center gap-5 md:justify-around items-center p-5 container-filter-0'>
                <div className='flex flex-col gap-3 md:justify-center md:items-center'>
                    <h1 className='text-2xl md:text-6xl font-bold'>CONTÁCTANOS</h1>
                    <h2 className='md:text-sm text-base font-regular'>Cotiza el servicio que deseas cotizar con nosotros.</h2>
                    <img src={logo} alt='logo' className='w-[261px] h-[50%] mx-auto hidden md:block' />
                </div>
                <div className='form w-[100%] md:w-[50%]'>
                    <form className='flex flex-col justify-between gap-5'>
                        <input name='name_client' type='text' className='border pl-6 border-black p-1 rounded-full bg-[#D9D9D9]' placeholder='Nombre' onChange={handleSubmit} />
                        <input name='last_name_client' type='text' className='border pl-6 border-black p-1 rounded-full bg-[#D9D9D9]' placeholder='Apellido' onChange={handleSubmit} />
                        <input name='company_name' type='text' className='border pl-6 border-black p-1 rounded-full bg-[#D9D9D9]' placeholder='Nombre de la compañia' onChange={handleSubmit} />
                        <input name='country' type='text' className='border pl-6 border-black p-1 rounded-full bg-[#D9D9D9]' placeholder='Pais' onChange={handleSubmit} />
                        <input name='email' type='text' className='border pl-6 border-black p-1 rounded-full bg-[#D9D9D9]' placeholder='Correo' onChange={handleSubmit} />
                        <input name='contact_number' type='text' className='border pl-6 border-black p-1 rounded-full bg-[#D9D9D9]' placeholder='Teléfono' onChange={handleSubmit} />
                        <textarea name='message' type='text' className='pl-6 resize-none h-40 md:h-30  p-1 bg-[#D9D9D9] rounded-xl' placeholder='Mensaje' onChange={handleSubmit} />
                        <button onClick={(e) => submit(e)} className='bg-white text-black border border-black  rounded-full p-2 self-end'>Enviar</button>
                    </form>

                </div>
            </div>

        </div>
    )
}

export default ContactoScreen