import React, { useEffect } from 'react'

function Modal() {
    useEffect(() => {
        setTimeout(() => {
            document.getElementById('my_modal_1').showModal()
        }, 1000)
    }, [])
    return (
        <dialog id="my_modal_1" className="modal">
            <div className="modal-box">
                <h3 className="font-bold text-lg">Bienvenido a Simple Soluciones</h3>
                <div className="modal-action">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn">cerrar</button>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default Modal