import React from 'react'
import Hero from '../../Components/Utils/Hero'
import DotPages from '../../Components/Utils/DotPages'
import Movistar from '../../Assets/Movistar.png'
import Havas from '../../Assets/Havas.png'
import Muller from '../../Assets/Muller.png'
import simpleTv from '../../Assets/SimpleTv.png'
import Norton from '../../Assets/Norton-Web.png'
import Renewed from '../../Assets/renewed-web.png'
import Transbank from '../../Assets/Transbank-web.png'
import ufro from '../../Assets/Ufro.png'
import sndp from '../../Assets/Servicio nacional de proteccion.png'
import Tome from '../../Assets/logo-footer.png'
import peñalolen from '../../Assets/Peñalolen.png'
import reduca from '../../Assets/logo_reduca-10-10.png'

import logos from '../../Assets/logos.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import CarouselCard from '../../Components/Utils/CarouselCard'


function ClientesScreen() {
  return (
    <div className='snap-y snap-mandatory relative w-full h-screen overflow-y-auto overflow-x-hidden scroll-smooth'>
      <div className='snap-start h-screen'>
        <Hero opacity={'opacity-[0.8]'} primerParrafo={'CONFÍA EN NOSOTROS'} segundoParrafo={'TU SATISFACCIÓN ES NUESTRA PRIORIDAD'} bgImage={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Simplesoluciones/pexels-fauxels-3245638-3840x2160-25fps.mp4'} dotsNumber={2} activeDot={0} />
      </div>
      <div className='snap-start'>
        <div className='w-full relative '>
          <div className='max-w-[1000px] 2xl:max-w-[1200px] w-full flex flex-col gap-5 justify-center items-center h-screen text-white mx-auto p-5 '>
            <div className='flex flex-col gap-5 w-full zindex'>
              <h2 className='text-2xl'>CONFÍAN EN NOSOTROS</h2>
              <div className='w-full border-t-2 border-b-2 bg-grilla bg-cover p-10'>
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                  className='w-full'
                  autoplay={{
                    delay: 800,

                  }}

                >
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={Movistar} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={Havas} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={Muller} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={simpleTv} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={Norton} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={Renewed} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={Transbank} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={ufro} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={sndp} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={Tome} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={peñalolen} />
                  </SwiperSlide>
                  <SwiperSlide className='w-full'>
                    <CarouselCard image={reduca} />
                  </SwiperSlide>

                </Swiper>

              </div>
              <div className='w-full md:border-t-2 md:border-b-2  p-5 flex flex-col md:flex-row gap-5 justify-between items-center mt-10'>
                <p className='text-xl'>CERTIFICACIONES QUE NOS RESPALDAN</p>
                <div className='bg-white rounded-3xl px-10'>
                  <img src={logos} className=' md:w-[500px] object-cover ' />
                </div>
              </div>

            </div>
          </div>
          <div className='bg-[#409891] w-[400px] h-[400px] md:h-[400px] 2xl:h-[500px] rounded-full blur-[200px] absolute bottom-0 right-0 z-10 '></div>
        </div>
      </div>
    </div>

  )
}

export default ClientesScreen