import React from 'react'


function HeroHome({ dotsNumber, activeDot, primerParrafo, segundoParrafo, bgImage, opacity, show, image }) {
    return (

        <header class="relative flex items-center justify-center h-screen  overflow-hidden">
            <div
                class="relative z-30 p-5 text-2xl text-white  bg-opacity-50 rounded-xl"
            >
                <div className='flex justify-center items-center gap-5'>
                    <img src={image} alt="" className='w-[100px]' />
                    <div className='md:text-left text-left flex flex-col items-start'>
                        <h1 class="text-2xl md:text-4xl font-bold md:text-center">{primerParrafo}</h1>
                        <h2 class="text-lg md:text-2xl md:text-center">{segundoParrafo}</h2>
                    </div>
                </div>
            </div>
            <video
                autoPlay
                loop
                muted
                class="absolute z-10 w-auto min-w-full md:min-h-full h-screen object-cover"
            >
                <source
                    src={bgImage}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </header>
    )
}

export default HeroHome