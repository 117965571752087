import React from 'react'

function CardNosotros({ titulo, parrafo, color, bgColor}) {
    return (
        <div className={`${bgColor} md:py-10 md:px-10 2xl:p-16 text-${color} rounded-3xl md:max-w-[350px] 2xl:max-w-[450px] flex flex-col gap-5 p-10`}>
            <h3 className='text-3xl font-bold text-left'>{titulo}</h3>
            <p className='text-left md:text-xs 2xl:text-base  font-normal'>{parrafo}</p>
        </div>
    )
}

export default CardNosotros