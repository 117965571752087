import React from 'react'
import buttonHablemos from '../../Assets/botonhablemos.png'
import ServiciosDetailsImg from '../../Assets/ServiciosDetailsImg.png'
import { NavLink, useLocation } from 'react-router-dom'
import { FaArrowAltCircleLeft } from 'react-icons/fa'

function ServicioDetail({ props }) {
    const location = useLocation()
    console.log(location.state)
    return (
        <div className='w-full 2xl:max-w-[1200px] max-w-[1000px] h-min 2xl:h-screen py-32 2xl:p-0 flex flex-col gap-5 justify-center items-center mx-auto bg-[#091615] text-white p-5'>
            <div className='flex justify-between items-center w-full '>
                <h1 className='text-3xl md:text-5xl border-t-2 pt-10'>{location.state.titulo}</h1>
                <NavLink to='/contacto'>
                    <img src={buttonHablemos} className='md:w-[240px] md:h-[170px] 2xl:w-[240px] 2xl:h-[200px] w-[100px] h-[100px]' />
                </NavLink>
            </div>
            <div className='w-full flex flex-col md:flex-row justify-between items-center gap-5  border-t-2 py-10'>
                <p className='md:w-[50%] md:text-sm 2xl:text-base text-left'>
                    {location.state.descriptionDetail}
                </p>
                <img src={ServiciosDetailsImg} className='md:w-[40%] 2xl:w-[50%] w-full  object-cover' />
            </div>

            <NavLink to={'/servicios'} className='self-start flex items-center gap-4 text-xl'>
                <FaArrowAltCircleLeft size={30} />
                Volver a servicios
            </NavLink>

        </div>
    )
}

export default ServicioDetail