import React from 'react'

function CarouselCard({ image }) {
    return (
        <div className='w-full h-[200px] flex justify-center items-center'>
            <img src={image} className='w-full max-h-[200px] object-contain' />

        </div>
    )
}

export default CarouselCard