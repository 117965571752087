import React from 'react'
import DotPages from './DotPages'
import scrollImage from '../../Assets/Scroll-Button.png'


function Hero({ dotsNumber, activeDot, primerParrafo, segundoParrafo, bgImage, opacity, show, image }) {
    return (
        <div className={` bg-no-repeat bg-cover text-white relative`}>
            <video loop autoPlay muted className='video-bg'>
                <source src={bgImage} type="video/mp4" />
            </video>
            <div className="noise"></div>
            <div className={`bg-[#091615] w-full h-screen absolute ${opacity}`}></div>
            <div className='max-w-[343px] md:max-w-[900px] h-screen mx-auto text-center flex flex-col justify-center container-filter-0'>
                {
                    image ? <img src={image} className='w-100 mx-auto' /> : null
                }
                <h1 className='text-[#409891] p-2 md:text-5xl sm:text-4xl text-4xl font-medium md:text-left text-left flex flex-col '>{primerParrafo}<span className='text-white font-bold p-2 md:text-5xl sm:text-4xl text-2xl md:text-left text-left leading-relaxed'>{segundoParrafo}</span></h1>
            </div>
        </div>
    )
}

export default Hero